import gql from "graphql-tag";

export const GET_OPS_SCREEN = gql`
  query GetOpsScreen(
    $startDate: DateTime!
    $endDate: DateTime!
    $buyerName: String
    $accountManagerName: String
    $verticalId: ID
    $subVerticalId: ID
    $fetchEDW: Boolean
    $timezone: String
  ) {
    LDPIngestQueryGroup {
      GetOpsScreen(
        StartDate: $startDate
        EndDate: $endDate
        BuyerName: $buyerName
        AccountManagerName: $accountManagerName
        VerticalId: $verticalId
        SubVerticalId: $subVerticalId
        FetchEDW: $fetchEDW
        Timezone: $timezone
      ) {
        AccountManagerName
        AveragePingTimeMs
        AveragePostTimeMs
        BuyerName
        PingAccepted
        PingAcceptedRate
        PingRejected
        PingCapacityRejected
        PingFilterRejected
        Pings
        PostAccepted
        PostAcceptedRate
        PostRejected
        PostCapacityRejected
        PostFilterRejected
        Posts
        Sold
        TotalRevenue
        RPT
        PostDefaultRejected
        PingDefaultRejected
      }
    }
  }
`;

export const GET_OPS_SCREEN_DETAILS = gql`
query GetOpsScreenDetails(
  $startDate: DateTime!
  $endDate: DateTime!
  $buyerName: String
  $accountManagerName: String
  $verticalId: ID
  $subVerticalId: ID
  $reportType: String!
  $dispositionId: ID
  $fetchEDW: Boolean
  $timezone: String
) {
  LDPIngestQueryGroup {
    GetOpsScreenDetails(
      StartDate: $startDate
      EndDate: $endDate
      BuyerName: $buyerName
      AccountManagerName: $accountManagerName
      VerticalId: $verticalId
      SubVerticalId: $subVerticalId
      ReportType: $reportType
      DispositionId: $dispositionId
      FetchEDW: $fetchEDW
      Timezone: $timezone
    ) {
      ClientTimeMilliSeconds
      ContractId
      ContractName
      LeadDataId
      PriceOffered
      ReceiveDate
      ResponseDispositionName
      ResponseStatus
      ResponseStatusCode
      SignupId
    }
  }
}
`;

export const GET_OPS_SCREEN_PING_POST_DETAILS = gql`
  query GetOpsScreenPingPostDetail(
    $LeadDataId: ID
    $ContractId: ID
    $VerticalId: ID
    $SubVerticalId: ID
    $IsPing: Int
    $fetchEdw: Boolean
  ) {
    LDPIngestQueryGroup {
      GetOpsScreenPingPostDetail(
        LeadDataId: $LeadDataId
        ContractId: $ContractId
        VerticalId: $VerticalId
        SubVerticalId: $SubVerticalId
        IsPing: $IsPing
        FetchEDW: $fetchEdw
      ) {
        LeadDetailId
        RequestPayload
        ResponsePayload
        RequestUrl
        ErrorMessage
        SignupId
      }
    }
  }
`;

export const GET_OPS_SCREEN_DISPOSITIONS = gql`
  query GetOpsScreenDispositions(
    $startDate: DateTime!
    $endDate: DateTime!
    $verticalId: ID!
    $subVerticalId: ID!
    $accountManagerName: String!
    $buyerName: String!
    $isSuccess: Int
    $isPost: Boolean!
    $fetchEdw: Boolean
    $timezone: String
  ) {
    LDPIngestQueryGroup {
      GetOpsScreenDisposition(
        StartDate: $startDate
        EndDate: $endDate
        VerticalId: $verticalId
        SubVerticalId: $subVerticalId
        AccountManagerName: $accountManagerName
        BuyerName: $buyerName
        IsSuccess: $isSuccess
        IsPost: $isPost
        FetchEDW: $fetchEdw
        TimeZone: $timezone
      ) {
        ResponseDispositionId
        ResponseDispositionName
        Volume
        IsSuccess
      }
    }
  }
`;

export const GET_OPS_SCREEN_REJECTED_FILTER_SUMMARY = gql`
  query GetOpsScreenRejectedFilterSummary(
    $startDate: DateTime!
    $endDate: DateTime!
    $verticalId: ID!
    $subVerticalId: ID!
    $accountManagerName: String!
    $buyerName: String!
    $isPost: Boolean!
    $fetchEdw: Boolean
    $timezone: String
  ) {
    LDPIngestQueryGroup {
      GetOpsScreenRejectedFilterSummary(
        StartDate: $startDate
        EndDate: $endDate
        VerticalId: $verticalId
        SubVerticalId: $subVerticalId
        AccountManagerName: $accountManagerName
        BuyerName: $buyerName
        IsPost: $isPost
        FetchEDW: $fetchEdw
        TimeZone: $timezone
      )
    }
  }
`;

export const GET_OPS_SCREEN_REJECTED_FILTER_DETAILS = gql`
  query GetOpsScreenRejectedFilterDetails(
    $startDate: DateTime!
    $endDate: DateTime!
    $verticalId: ID!
    $subVerticalId: ID!
    $accountManagerName: String!
    $buyerName: String!
    $isPost: Boolean!
    $fetchEdw: Boolean
    $responseDispositionId: ID!
    $timezone: String
  ) {
    LDPIngestQueryGroup {
      GetOpsScreenRejectedFilterDetails(
        StartDate: $startDate
        EndDate: $endDate
        VerticalId: $verticalId
        SubVerticalId: $subVerticalId
        AccountManagerName: $accountManagerName
        BuyerName: $buyerName
        IsPost: $isPost
        FetchEDW: $fetchEdw
        ResponseDispositionId: $responseDispositionId
        TimeZone: $timezone
      )
    }
  }
`;
